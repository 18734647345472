const generateRandomChars = (() => {
  const possibleChars = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const numPossibleChars = possibleChars.length;
  return (numChars) =>
    Array(numChars)
      .fill()
      .map(() => possibleChars[Math.floor(Math.random() * numPossibleChars)])
      .join("");
})();

export const generateId = (prefix) => {
  const epochMilliseconds = new Date().getTime();
  return `${prefix || "GEN"}-${epochMilliseconds}-${generateRandomChars(5)}`;
};
